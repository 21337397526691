import arrowIcon from "../../assets/media/arrow-icon.svg";

import "./index.css";

const SelectMenu = ({ initialSelectName, type, value, dispatch, data }) => {
  const handleSelectChange = (event) => {
    dispatch({
      type: type,
      payload: event.target.value.toLowerCase(),
    });
  };

  return (
    <div className="selectMenu">
      <div className="selectMenu__current" tabIndex="1">
        <div className="selectMenu__value">
          <input
            className="selectMenu__input"
            type="radio"
            id={initialSelectName}
            value=""
            name={initialSelectName}
            checked={value === ""}
            onChange={handleSelectChange}
          />

          <p className="selectMenu__text">{initialSelectName}</p>
        </div>

        {data.map((row, index) => (
          <div className="selectMenu__value" key={index}>
            <input
              className="selectMenu__input"
              type="radio"
              id={row.name + index}
              value={row.name}
              name={row.name}
              checked={value === row.name.toLowerCase()}
              onChange={handleSelectChange}
            />

            <p className="selectMenu__text">{row.name}</p>
          </div>
        ))}

        <img
          className="selectMenu__icon"
          src={arrowIcon}
          alt="arrow icon"
          aria-hidden="true"
        />
      </div>

      <ul className="selectMenu__list">
        <li>
          <label className="selectMenu__option" htmlFor={initialSelectName}>
            Reset
          </label>
        </li>

        {data.map((row, index) => (
          <li key={index}>
            <label
              className="selectMenu__option"
              htmlFor={row.name + index}
              style={{ color: value === row.name.toLowerCase() && "#ffffff" }}
            >
              {row.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectMenu;

import { useReducer, useEffect } from "react";
import axios from "axios";

import keys from "../config/keys";

const requestReducer = (state, action) => {
  try {
    if (action.payload) return action.payload;

    throw new Error(
      "The request reducer accepts request configuration in a payload object only. The request reducer is using axios package to manage requests, check its request configuration for more details at: `https://www.npmjs.com/package/axios`"
    );
  } catch (error) {
    console.error(error);

    return state;
  }
};

const responseInitialState = {
  isFetching: false,
  data: null,
};

const responseReducer = (state, action) => {
  switch (action.type) {
    case "fetching":
      return { ...responseInitialState, isFetching: true };
    case "done":
      return {
        isFetching: false,
        data: action.payload,
      };
    default:
      try {
        throw new Error(
          "Invalid dispatchResponse action type passed in useFetch hook reducer!"
        );
      } catch (error) {
        console.error(error);

        return { ...state, isFetching: false };
      }
  }
};

const useFetch = () => {
  const [request, dispatchRequest] = useReducer(requestReducer, null);

  const [response, dispatchResponse] = useReducer(
    responseReducer,
    responseInitialState
  );

  useEffect(() => {
    if (!request) return;

    let isMounted = true;

    const fetchRequest = async () => {
      try {
        const axiosInstance = axios.create({
          baseURL: keys.backendBaseURL,
        });

        axiosInstance.interceptors.request.use(
          (config) => {
            isMounted && dispatchResponse({ type: "fetching" });

            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );

        const res = await axiosInstance(request);

        isMounted &&
          dispatchResponse({
            type: "done",
            payload: res.data,
          });
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 404:
              isMounted &&
                dispatchResponse({
                  type: "done",
                  payload: {
                    message: "notFoundError",
                    status: "fail",
                  },
                });
              break;
            default:
              isMounted &&
                dispatchResponse({
                  type: "done",
                  payload: {
                    message: "somethingWentWrongError",
                    status: "fail",
                  },
                });
              break;
          }
        } else if (error.request) {
          isMounted &&
            dispatchResponse({
              type: "done",
              payload: {
                message: "noResponseError",
                status: "fail",
              },
            });
        } else {
          isMounted &&
            dispatchResponse({
              type: "done",
              payload: {
                message: "settingUpRequestError",
                status: "fail",
              },
            });
        }
      }
    };

    fetchRequest();

    return () => {
      isMounted = false;
    };
  }, [request]);

  return { response, dispatchRequest };
};

export default useFetch;

import "./index.css";

const Container = ({ showBottomBorder = true, children }) => {
  return (
    <div
      className={`containerComponent ${
        showBottomBorder ? "has-border-bottom" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Container;

import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import keys from "./config/keys";

import App from "./App";

import "./index.css";

ReactGA.initialize(keys.googleAnalyticsTrackingId);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

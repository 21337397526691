import { useState } from "react";

import atlanticCouncilLogo from "../../assets/media/atlantic-council-logo.svg";
import empowerLogo from "../../assets/media/empower-logo.svg";

import "./index.css";

const WelcomeModal = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isModalHidden, setIsModalHidden] = useState(
    localStorage.getItem("welcomeModal") ? true : false
  );

  const toggleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  const hideModal = () => {
    if (isChecked) {
      localStorage.setItem(
        "welcomeModal",
        "Walid Hawari will not show you the modal again by using the localStorage web API"
      );
    }

    setIsModalHidden(true);
  };

  return !isModalHidden ? (
    <div className="welcomeModal__wrap">
      <div className="welcomeModal">
        <div className="welcomeModal__header">
          <img
            className="welcomeModal__atlanticLogo"
            src={atlanticCouncilLogo}
            alt="atlantic council logo"
          />

          <img
            className="welcomeModal__empowerMeLogo"
            src={empowerLogo}
            alt="empower logo"
          />
        </div>

        <div className="welcomeModal__body">
          <p>
            We collaborated with{" "}
            <a
              className="welcomeModal__hyperlink"
              href="https://imaginelabs.me"
              target="_blank"
              rel="noreferrer"
            >
              Imagine Labs
            </a>{" "}
            to bring you this interactive tracker, which maps out the
            organizations that Middle East governments have founded, funded,
            and/or partnered with in order to support entrepreneurs, startups,
            and SMEs. Click to explore some of the networks nurturing innovation
            in Bahrain, Egypt, Jordan, Qatar, Saudi Arabia, and the United Arab
            Emirates.
          </p>
        </div>

        <div className="welcomeModal__footer">
          <button className="welcomeModal__exploreButton" onClick={hideModal}>
            Explore
          </button>

          <div className="welcomeModal__labelWrap">
            <label className="welcomeModal__label">
              <input
                id="hideModalCheckbox"
                type="checkbox"
                checked={isChecked}
                onChange={toggleChecked}
              />

              <span className="welcomeModal__checkMark"></span>
            </label>

            <label htmlFor="hideModalCheckbox">Don't show again</label>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default WelcomeModal;

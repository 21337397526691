import "./index.css";

const ZoomControls = ({ zoomLevel, onZoomChange, zoomIn, zoomOut }) => {
  return (
    <div className="zoomControls">
      <button onClick={zoomIn}>+</button>

      <div className="zoomControls__rangeWrap">
        <input
          className="zoomControls__range"
          type="range"
          orient="vertical"
          min="0.25"
          max="4"
          step="0.25"
          value={zoomLevel}
          onChange={onZoomChange}
        />
      </div>

      <button onClick={zoomOut}>-</button>
    </div>
  );
};

export default ZoomControls;

import facebookIcon from "../../assets/media/facebook-icon.svg";
import twitterIcon from "../../assets/media/twitter-icon.svg";
import instagramIcon from "../../assets/media/instagram-icon.svg";
import youtubeIcon from "../../assets/media/youtube-icon.svg";
import linkedinIcon from "../../assets/media/linkedin-icon.svg";

import "./index.css";

const IconWrap = ({ address, icon, alt, ...rest }) => {
  return (
    <a {...rest} href={address} target="_blank" rel="noreferrer">
      <img src={icon} alt={alt} />
    </a>
  );
};

const SocialMediaAccounts = ({ accounts }) => {
  return (
    <div className="socialMediaAccounts">
      {accounts?.facebook && (
        <IconWrap
          className="socialMediaAccounts__iconWrap facebook"
          icon={facebookIcon}
          address={accounts.facebook}
          alt="facebook icon"
        />
      )}

      {accounts?.twitter && (
        <IconWrap
          className="socialMediaAccounts__iconWrap twitter"
          icon={twitterIcon}
          address={accounts.twitter}
          alt="twitter icon"
        />
      )}

      {accounts?.instagram && (
        <IconWrap
          className="socialMediaAccounts__iconWrap instagram"
          icon={instagramIcon}
          address={accounts.instagram}
          alt="instagram icon"
        />
      )}

      {accounts?.youtube && (
        <IconWrap
          className="socialMediaAccounts__iconWrap youtube"
          icon={youtubeIcon}
          address={accounts.youtube}
          alt="youtube icon"
        />
      )}

      {accounts?.linkedin && (
        <IconWrap
          className="socialMediaAccounts__iconWrap linkedin"
          icon={linkedinIcon}
          address={accounts.linkedin}
          alt="linkedin icon"
        />
      )}
    </div>
  );
};

export default SocialMediaAccounts;

import glanceBars from "../../assets/media/glance-bars.svg";
import glanceText from "../../assets/media/glance-text.svg";
import glanceLines from "../../assets/media/glance-lines.svg";

import "./index.css";

const Glance = () => {
  return (
    <div className="glance">
      <img src={glanceBars} alt="bars" />

      <img className="glance__text" src={glanceText} alt="text" />

      <img src={glanceLines} alt="lines" />
    </div>
  );
};

export default Glance;

import { useState } from "react";

import Slider from "../Slider";
import Title from "../Title";
import showMoreIcon from "../../assets/media/show-more-icon.svg";

import "./index.css";

const Programs = ({ programs = [], isParent = false }) => {
  return (
    <div className="programs">
      <Title>{isParent ? "Initiatives" : "Programs"}</Title>

      <Slider>
        {programs.map((program, index) => (
          <ProgramCard key={index} program={program} />
        ))}
      </Slider>
    </div>
  );
};

const ProgramCard = ({ program }) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <div className={`programs__card ${isShowMore ? "" : "hidden-content"}`}>
      <div className="programs__cardHeader">
        <h4>{program?.title || ""}</h4>
      </div>

      <div className={`programs__cardBody ${isShowMore ? "" : "hidden-body"}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: program?.description,
          }}
        ></div>
      </div>

      <div className="programs__cardFooter">
        {program?.tags &&
          Array.isArray(program.tags) &&
          program.tags.length > 0 && (
            <div
              className={`programs__tagWrap ${isShowMore ? "" : "hidden-tags"}`}
            >
              {program.tags.map((tag, index) => (
                <ProgramTag key={index} tag={tag} />
              ))}
            </div>
          )}

        <div className={`programs__showMore ${isShowMore ? "collapsed" : ""}`}>
          <button onClick={toggleShowMore}>
            <img src={showMoreIcon} alt="show more" />
          </button>
        </div>
      </div>
    </div>
  );
};

const ProgramTag = ({ tag }) => {
  return tag?.name ? (
    <div
      className="programs__tag"
      style={{ backgroundColor: tag?.color || "#60215c" }}
    >
      <span>{tag.name}</span>
    </div>
  ) : null;
};

export default Programs;

import poweredByImagineLabs from "../../assets/media/powered-by-imagine-labs.svg";

import "./index.css";

const PoweredBy = () => {
  return (
    <a
      className="poweredBy"
      href="https://imaginelabs.me/"
      target="_blank"
      rel="noreferrer"
    >
      <img src={poweredByImagineLabs} alt="Imagine Labs" />
    </a>
  );
};

export default PoweredBy;

import spinnerImg from "../../assets/media/spinner.svg";

import "./index.css";

const Spinner = () => {
  return (
    <div className="spinner">
      <img src={spinnerImg} alt="spinner" />
    </div>
  );
};

export default Spinner;



import { useEffect, useRef } from "react";

import "./index.css";

const Slider = ({ children }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const currentSlider = sliderRef.current;

    let position = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (event) {
      currentSlider.style.userSelect = "none";

      position = {
        left: currentSlider.scrollLeft,
        top: currentSlider.scrollTop,
        // Get the current mouse position
        x: event.clientX,
        y: event.clientY,
      };

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (event) {
      // How far the mouse has been moved
      const dx = event.clientX - position.x;
      const dy = event.clientY - position.y;

      // Scroll the element
      currentSlider.scrollTop = position.top - dy;
      currentSlider.scrollLeft = position.left - dx;
    };

    const mouseUpHandler = function () {
      currentSlider.style.removeProperty("user-select");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    currentSlider.addEventListener("mousedown", mouseDownHandler);

    return () => {
      currentSlider.removeEventListener("mousedown", mouseDownHandler);
    };
  }, []);

  return (
    <div className="sliderComponent" ref={sliderRef}>
      {children}
    </div>
  );
};

export default Slider;

import ReactGA from "react-ga";

const useGoogleAnalyticsEventTracker = (category = "") => {
  const eventTracker = (action = "") => {
    ReactGA.event({ category, action });
  };

  return eventTracker;
};

export default useGoogleAnalyticsEventTracker;

import "./index.css";

const ResetFiltersButton = ({ resetFilters }) => {
  return (
    <button className="resetFiltersButton" onClick={resetFilters}>
      Reset Filters
    </button>
  );
};

export default ResetFiltersButton;

import arrowIcon from "../../assets/media/arrow-icon.svg";
import globeIcon from "../../assets/media/globe-icon.svg";

import "../SelectMenu/index.css";
import "./index.css";

const CountryView = ({
  setFilter,
  countries,
  countryID,
  setCountryID,
  setIsSidePanelActive,
}) => {
  const handleSelectChange = (event) => {
    const value = event.target.value.toLowerCase();

    if (!value) {
      setCountryID("");

      setFilter("");

      return;
    }

    setIsSidePanelActive(false);

    setCountryID(value);

    setFilter(value);
  };

  return countries ? (
    <div className="selectMenu">
      <div className="selectMenu__current" tabIndex="1">
        <div className="selectMenu__value">
          <input
            className="selectMenu__input"
            type="radio"
            id="countries-view"
            value=""
            name="countries-view"
            checked={countryID === ""}
            onChange={handleSelectChange}
          />

          <p className="selectMenu__text">Countries</p>
        </div>

        {countries
          .sort((firstElement, secondElement) => {
            // Sort countries alphabetically
            let firstElementCountryName = firstElement.name.toLowerCase();
            let secondElementCountryName = secondElement.name.toLowerCase();

            if (firstElementCountryName < secondElementCountryName) {
              return -1;
            }

            if (firstElementCountryName > secondElementCountryName) {
              return 1;
            }

            return 0;
          })
          .map((row) => (
            <div className="selectMenu__value" key={row.id}>
              <input
                className="selectMenu__input"
                type="radio"
                id={row.id}
                value={row.id}
                name={row.name}
                checked={countryID === row.id.toLowerCase()}
                onChange={handleSelectChange}
              />

              <p className="selectMenu__text">{row.name}</p>
            </div>
          ))}

        <img
          className="selectMenu__icon"
          src={arrowIcon}
          alt="arrow icon"
          aria-hidden="true"
        />
      </div>

      <ul className="selectMenu__list">
        <li className="selectMenu__listItem">
          <i className="selectMenu__listIcon">
            <img src={globeIcon} alt={"globe icon"} />
          </i>

          <label
            className="selectMenu__option"
            htmlFor="countries-view"
            style={{ color: countryID === "" && "#ffffff" }}
          >
            Countries
          </label>
        </li>

        {countries.map((row) => (
          <li key={row.id} className="selectMenu__listItem">
            <i className="selectMenu__listIcon">
              <img src={row.flag} alt={row.name + " icon"} />
            </i>

            <label
              className="selectMenu__option"
              htmlFor={row.id}
              style={{ color: countryID === row.id && "#ffffff" }}
            >
              {row.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default CountryView;

import { useState } from "react";
import CountUp from "react-countup";

import "./index.css";

const Statistics = ({ statistics = [] }) => {
  return (
    <div className="statistics">
      {statistics.map((statistic, index) => (
        <StatisticContainer key={index} statistic={statistic} />
      ))}
    </div>
  );
};

const StatisticContainer = ({ statistic: { title, value, symbol } }) => {
  const [isCountEnd, setIsCountEnd] = useState(false);

  return (
    <div className="statistics__wrap">
      <div>
        <div className="statistics__line"></div>

        <h4 className="statistics__title">{title}</h4>
      </div>

      <p className="statistics__stat">
        <CountUp
          end={value}
          duration={2}
          separator=","
          onStart={() => setIsCountEnd(false)}
          onEnd={() => setIsCountEnd(true)}
        />

        <span
          className={`statistics__symbol ${isCountEnd ? "show-symbol" : ""}`}
        >
          {" " + symbol}
        </span>
      </p>
    </div>
  );
};

export default Statistics;

import SelectMenu from "../SelectMenu";

const Filters = ({ filters, dispatchFilters, filtersData }) => {
  return filtersData ? (
    <>
      {filtersData.beneficiaries.length > 0 && (
        <SelectMenu
          initialSelectName="Beneficiaries"
          type="beneficiaries"
          value={filters.beneficiaries}
          dispatch={dispatchFilters}
          data={filtersData.beneficiaries || []}
        />
      )}

      {filtersData.sectors.length > 0 && (
        <SelectMenu
          initialSelectName="Sectors"
          type="sectors"
          value={filters.sectors}
          dispatch={dispatchFilters}
          data={filtersData.sectors || []}
        />
      )}

      {filtersData.funding.length > 0 && (
        <SelectMenu
          initialSelectName="Funding Stage"
          type="funding"
          value={filters.funding}
          dispatch={dispatchFilters}
          data={filtersData.funding || []}
        />
      )}

      {filtersData.services.length > 0 && (
        <SelectMenu
          initialSelectName="Services"
          type="services"
          value={filters.services}
          dispatch={dispatchFilters}
          data={filtersData.services || []}
        />
      )}
    </>
  ) : null;
};

export default Filters;

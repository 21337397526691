import atlanticCouncilLogo from "../../assets/media/atlantic-council-logo.svg";
import empowerLogo from "../../assets/media/empower-logo.svg";

import "./index.css";

const SiteLogo = () => {
  return (
    <div className="siteLogo">
      <a
        href="https://www.atlanticcouncil.org/programs/middle-east-programs/rafik-hariri-center-for-the-middle-east/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="siteLogo__atlantic"
          src={atlanticCouncilLogo}
          alt="atlantic council"
        />
      </a>

      <a
        href="https://www.atlanticcouncil.org/programs/middle-east-programs/rafik-hariri-center-for-the-middle-east/empowerme/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="siteLogo__empower" src={empowerLogo} alt="empowerMe" />
      </a>
    </div>
  );
};

export default SiteLogo;

import "./index.css";

const CompanyWebsite = ({ address }) => {
  return (
    <a
      className="companyWebsite"
      href={address}
      target="_blank"
      rel="noreferrer"
    >
      {address}
    </a>
  );
};

export default CompanyWebsite;

import closeIcon from "../../assets/media/close-icon.svg";

import Container from "../Container";
import SocialMediaAccounts from "../SocialMediaAccounts";
import CompanyWebsite from "../CompanyWebsite";
import CompanyMission from "../CompanyMission";
import Programs from "../Programs";
import Glance from "../Glance";
import Statistics from "../Statistics";
import ParentInstitutions from "../ParentInstitutions";
import PartnersAndSponsors from "../PartnersAndSponsors";

import "./index.css";

const SidePanel = ({ isSidePanelActive, handleSidePanelClose, nodeData }) => {
  return (
    <nav
      className={`sidePanel ${isSidePanelActive ? "sidePanel__active" : ""}`}
    >
      <header className="sidePanel__header">
        <div className="sidePanel__headerLogo">
          <img src={nodeData?.nodeLogo || ""} alt="company logo" />
        </div>

        <button onClick={handleSidePanelClose}>
          <img src={closeIcon} alt="close" />
        </button>
      </header>

      <div className="sidePanel__body">
        {nodeData?.socialAccounts || nodeData?.companyWebsiteURL ? (
          <Container>
            {nodeData?.socialAccounts && (
              <SocialMediaAccounts accounts={nodeData.socialAccounts || null} />
            )}

            {nodeData?.companyWebsiteURL && (
              <CompanyWebsite address={nodeData.companyWebsiteURL || ""} />
            )}
          </Container>
        ) : null}

        {nodeData?.companyMission ? (
          <Container>
            <CompanyMission
              companyName={nodeData?.companyName || ""}
              mission={nodeData.companyMission || ""}
            />
          </Container>
        ) : null}

        {nodeData?.programs && nodeData.programs.length > 0 ? (
          <Container>
            <Programs
              programs={nodeData?.programs || []}
              isParent={nodeData?.isParent || false}
            />
          </Container>
        ) : null}

        {nodeData?.statistics && nodeData.statistics.length > 0 ? (
          <>
            <Container showBottomBorder={false}>
              <Glance />
            </Container>

            <Container>
              <Statistics statistics={nodeData.statistics || []} />
            </Container>
          </>
        ) : null}

        {nodeData?.parentInstitutions &&
        nodeData.parentInstitutions.length > 0 ? (
          <Container>
            <ParentInstitutions
              institutions={nodeData.parentInstitutions || []}
            />
          </Container>
        ) : null}

        {nodeData?.partnersAndCorporateSponsors &&
        nodeData.partnersAndCorporateSponsors.length > 0 ? (
          <Container showBottomBorder={false}>
            <PartnersAndSponsors
              partnersAndSponsors={nodeData.partnersAndCorporateSponsors || []}
            />
          </Container>
        ) : null}
      </div>
    </nav>
  );
};

export default SidePanel;

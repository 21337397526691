import { useRef, useState, useEffect, useReducer, useCallback } from "react";
import { NetChart } from "@dvsl/zoomcharts";

import keys from "../../config/keys";
import useFetch from "../../hooks/useFetch";
import useGoogleAnalyticsEventTracker from "../../hooks/useGoogleAnalyticsEventTracker";

import Spinner from "../Spinner";
import SiteLogo from "../SiteLogo";
import CollaborateWithUs from "../CollaborateWithUs";
import SidePanel from "../SidePanel";
import PoweredBy from "../PoweredBy";
import ZoomControls from "../ZoomControls";
import ToggleMode from "../ToggleMode";
import ShowAllButton from "../ShowAllButton";
import CountryView from "../CountryView";
import Filters from "../Filters";
import ResetFiltersButton from "../ResetFiltersButton";
import WelcomeModal from "../WelcomeModal";

import "./index.css";

window.ZoomChartsLicense = keys.zoomChartsLicense;
window.ZoomChartsLicenseKey = keys.zoomChartsLicenseKey;

const nodeStyle = (node) => {
  const nodeExtraData = node?.data?.extra;

  if (nodeExtraData.isCountry) {
    node.radius = 87;
    node.fillColor = "transparent";
    node.lineWidth = 3;
    node.lineColor = "#A998A6";
  } else if (nodeExtraData.isParent) {
    node.radius = 35.5;
  } else {
    node.radius = 46.5;
  }

  node.items = [
    {
      image: node.data.extra.nodeLogo,
    },
  ];
};

const initialFiltersState = {
  beneficiaries: "",
  sectors: "",
  funding: "",
  services: "",
  visited: false,
};

const filtersReducer = (state, action) => {
  switch (action.type) {
    case "beneficiaries":
      return { ...state, beneficiaries: action.payload, visited: true };
    case "sectors":
      return { ...state, sectors: action.payload, visited: true };
    case "funding":
      return { ...state, funding: action.payload, visited: true };
    case "services":
      return { ...state, services: action.payload, visited: true };
    case "reset":
    default:
      return { ...initialFiltersState, visited: true };
  }
};

let startNodeID = null;
let endNodeID = null;
let pathIDs = {};

let initialNodes = [];
let filteredNode = "";
let allNodeData = null;
let allNodeSetTime = 0;

const NetworkGraph = () => {
  const { response, dispatchRequest } = useFetch();
  const networkGraphRef = useRef(null);
  const [isSidePanelActive, setIsSidePanelActive] = useState(false);
  const [nodeData, setNodeData] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isModeToggled, setIsModeToggled] = useState(false);
  const [filters, dispatchFilters] = useReducer(
    filtersReducer,
    initialFiltersState
  );
  const [responseData, setResponseData] = useState(undefined);
  const [countryID, setCountryID] = useState("");
  const [isShowingAll, setIsShowingAll] = useState(false);
  const trackCountry = useGoogleAnalyticsEventTracker("Country");
  const trackOrganization = useGoogleAnalyticsEventTracker("Organization");

  const handleSidePanelClose = () => {
    setIsSidePanelActive(false);

    setCountryID("");

    networkGraphRef.current.zoom(1);
    setZoomLevel(1);
  };

  const onZoomChange = (event) => {
    const { value } = event.target;

    setZoomLevel(+value);
    networkGraphRef.current.zoom(+value);
  };

  const zoomIn = () => {
    if (zoomLevel > 3.75) return;

    const zoomInValue = zoomLevel + 0.25;

    setZoomLevel(zoomInValue);
    networkGraphRef.current.zoom(zoomInValue);
  };

  const zoomOut = () => {
    if (zoomLevel < 0.5) return;

    const zoomOutValue = zoomLevel - 0.25;

    setZoomLevel(zoomOutValue);
    networkGraphRef.current.zoom(zoomOutValue);
  };

  const handleModeChange = () => {
    networkGraphRef.current.updateSettings({
      layout: {
        mode: isModeToggled ? "dynamic" : "radial",
      },
    });

    setIsModeToggled(!isModeToggled);
  };

  const generateAllNodeData = () => {
    const time = Date.now();

    if (allNodeData && time - allNodeSetTime < 50) {
      return allNodeData;
    }

    const data = networkGraphRef.current.exportData(false);

    const result = {};

    data.links.forEach((link) => {
      if (!result.hasOwnProperty(link.from)) {
        result[link.from] = [];
      }
      if (!result.hasOwnProperty(link.to)) {
        result[link.to] = [];
      }
      result[link.from].push(link);
      result[link.to].push(link);
    });

    allNodeData = result;

    allNodeSetTime = Date.now();

    return result;
  };

  const deepExpand = (id, depth, visited, allNodes) => {
    if (depth === 0) {
      return;
    }

    visited = visited || {};

    if (visited.hasOwnProperty(id)) {
      return;
    }

    networkGraphRef.current.expandNode(id);

    visited[id] = true;

    if (depth > 1) {
      allNodes = allNodes || generateAllNodeData();

      allNodes[id].forEach((link) =>
        deepExpand(
          link.from === id ? link.to : link.from,
          depth - 1,
          visited,
          allNodes
        )
      );
    }
  };

  const deepCollapse = (id, depth, visited, allNodes) => {
    if (depth === 0) {
      return;
    }
    visited = visited || {};
    if (visited.hasOwnProperty(id)) {
      return;
    }

    networkGraphRef.current.hideNode(id);

    visited[id] = true;

    if (depth > 1) {
      allNodes = allNodes || generateAllNodeData();
      allNodes[id].forEach((link) =>
        deepCollapse(
          link.from === id ? link.to : link.from,
          depth - 1,
          visited,
          allNodes
        )
      );
    }
  };

  const showAll = (value) => {
    const expandAll = () => {
      initialNodes
        .filter((id) => !filteredNode || id === filteredNode)
        .forEach((id) => {
          networkGraphRef.current.showNode(id);
          deepExpand(id, 1000);
        });

      networkGraphRef.current.zoom(0.25);
      setZoomLevel(0.25);
    };

    const collapseAll = () => {
      initialNodes
        .filter((id) => !filteredNode || id === filteredNode)
        .forEach((id) => {
          deepCollapse(id, 1000);
          networkGraphRef.current.showNode(id);
        });

      networkGraphRef.current.zoom(1);
      setZoomLevel(1);
    };

    if (typeof value === "boolean") {
      value ? expandAll() : collapseAll();

      return;
    }

    if (!isShowingAll) {
      expandAll();

      setIsShowingAll(true);
    } else {
      collapseAll();

      setIsShowingAll(false);
    }
  };

  const setFilter = (value) => {
    const newFilteredNode = value;

    initialNodes.forEach((id) => {
      if (newFilteredNode && id !== newFilteredNode) {
        deepCollapse(id, 1000);
      } else {
        networkGraphRef.current.showNode(id);
        if (newFilteredNode && id === newFilteredNode) {
          deepExpand(id, 1000);
        }
      }
    });

    filteredNode = newFilteredNode;

    if (!value) return;
    setTimeout(() => {
      networkGraphRef.current.scrollIntoView([value]);
    }, 250);
  };

  const resetFilters = () => {
    dispatchFilters({
      type: "reset",
    });
  };

  const changeTargetNode = useCallback((node) => {
    let nodeId = node.id;

    let countryNodeID = node?.data?.extra?.countryId || null;

    if (countryNodeID) {
      startNodeID = countryNodeID;
    } else {
      return;
    }

    if (nodeId !== endNodeID) {
      endNodeID = nodeId;

      if (endNodeID) {
        pathIDs = computePath();
      } else {
        pathIDs = {};
      }

      networkGraphRef.current.updateStyle();
    }
  }, []);

  const computePath = () => {
    let from = networkGraphRef.current.getNode(startNodeID.toLowerCase());
    let to = networkGraphRef.current.getNode(endNodeID.toLowerCase());
    let back = {};
    let queue = [from];
    let link, next, current;

    while (queue.length > 0) {
      current = queue.shift();

      if (current === to) break;

      for (let i in current.links) {
        link = current.links[i];
        next = link.otherEnd(current);

        if (back[next.id]) continue;

        back[next.id] = link;

        queue.push(next);
      }
    }

    let result = {};

    while (current !== from) {
      link = back[current.id];
      current = link.otherEnd(current);
      result[link.id] = true;
      result[current.id] = true;
    }

    return result;
  };

  const linkHighlight = (link) => {
    if (pathIDs[link.id]) {
      link.fillColor = "#054379";
    }
  };

  useEffect(() => {
    dispatchRequest({
      payload: {
        method: "get",
        url: "api/general",
      },
    });
  }, [dispatchRequest]);

  useEffect(() => {
    if (!response.data) return;

    setResponseData(response.data);

    const links = response.data?.links || [];
    const nodes = response.data?.nodes || [];
    let countries = [];

    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index]?.extra?.isCountry) {
        countries.push(nodes[index].id);
      }
    }

    initialNodes = countries;

    networkGraphRef.current.updateSettings({
      data: {
        preloaded: {
          nodes: nodes,
          links: links,
        },
      },
      navigation: {
        initialNodes: countries,
        numberOfFocusNodes: countries.length,
      },
    });
  }, [response.data]);

  useEffect(() => {
    networkGraphRef.current = new NetChart({
      title: {
        enabled: false,
      },
      toolbar: {
        enabled: false,
        items: [],
      },
      container: "networkGraphContainer",
      data: null,
      navigation: {
        mode: "manual",
        autoZoomOnFocus: true,
      },
      interaction: {
        zooming: {
          initialAutoZoom: "overview",
          sensitivity: 4,
          autoZoomPositionElasticity: 0.000025,
          wheel: false,
          fingers: false,
          doubleClickZoom: 0,
          zoomExtent: [0.25, 4],
        },
        selection: {
          lockNodesOnMove: false,
        },
      },
      layout: {
        nodeSpacing: 100,
      },
      events: {
        onClick: function (event) {
          if (event.clickLink) return;

          if (event.clickNode) {
            const clickedNode = event.clickNode;

            networkGraphRef.current.scrollIntoView([clickedNode]);

            if (!clickedNode?.data?.extra?.isCountry) {
              setCountryID("");

              trackOrganization(clickedNode.data.extra.companyName);

              setTimeout(() => {
                networkGraphRef.current.zoom(4);
                setZoomLevel(4);

                setIsSidePanelActive(true);
                setNodeData(clickedNode?.data || null);
              }, 250);
            } else {
              setCountryID(clickedNode.data.id);

              trackCountry(clickedNode.data.extra.countryName);

              const isFocusNode = initialNodes
                .filter((id) => !filteredNode || id === filteredNode)
                .some((id) => id === clickedNode.data.id);

              if (clickedNode.expanded) {
                if (isFocusNode) {
                  deepCollapse(clickedNode.data.id, 1000);
                }
              } else {
                if (isFocusNode) {
                  deepExpand(clickedNode.data.id, 1000);
                }
              }

              initialNodes
                .filter((id) => !filteredNode || id === filteredNode)
                .forEach((id) => {
                  networkGraphRef.current.showNode(id);
                });

              event.defaultPrevented = true;

              setIsSidePanelActive(false);
            }

            return;
          }

          // setCountryID("");

          setIsSidePanelActive(false);

          networkGraphRef.current.zoom(1);
          setZoomLevel(1);
        },
        onDoubleClick: function (event) {
          if (event.clickNode) {
            event.preventDefault();
          }
        },
        onRightClick: function (event) {
          if (event.clickNode) {
            event.preventDefault();
          }
        },
        onHoverChange: function (event) {
          if (event.hoverNode) changeTargetNode(event.hoverNode);

          if (event.hoverItem || event.hoverLink) {
            event.preventDefault();
          }
        },
      },
      info: {
        enabled: true,
        nodeContentsFunction: function (itemData) {
          if (itemData?.extra?.isCountry) {
            if (itemData?.extra?.countryName) {
              return (
                "<div style='margin: auto; max-width: 300px; background-color: rgba(5, 67, 121, 0.8); border: 1px solid rgba(5, 67, 121, 0.8); border-radius: 3px; stroke-linecap: butt; stroke-linejoin: miter; text-align: center; padding: 12px;'>" +
                "<h2 style='margin: unset; color: #ffffff; text-transform: capitalize;'>" +
                itemData.extra.countryName +
                "</h2>" +
                "</div>"
              );
            }

            return null;
          }

          return (
            "<div class='cardHover' style='margin:auto; width: 300px; background-color: #054379; border: 1px solid #054379; border-radius: 3px; outline: 1px solid #054379; stroke-linecap: butt; stroke-linejoin: miter;'>" +
            "<div style='position: relative; height: 200px; background-color: #ffffff; display: flex; align-items: center; justify-content: center; margin-bottom: 16px;'>" +
            `<img src='${itemData?.extra?.nodeLogo}' alt='banner' style='width: auto; height: 200px; object-fit: contain;' >` +
            "</div>" +
            "<div style='padding: 0px 12px;'>" +
            "<h1 style='font: 600 22px/30px proximaNova, sans-serif; color: #ffffff;'>" +
            itemData?.extra?.companyName +
            "</h1>" +
            "</div>" +
            "<div style='padding: 12px;'>" +
            "<p style='font: 400 13px/19px proximaNova, sans-serif; max-width: 100%; color: white; display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden;'>" +
            itemData?.extra?.shortDescription +
            "</p>" +
            "</div>" +
            "</div>"
          );
        },
      },
      style: {
        node: {
          display: "image",
          imageCropping: true,
          lineWidth: 0,
          cursor: "pointer",
          fillColor: "#ffffff",
        },
        nodeFocused: {
          lineWidth: 3,
          lineColor: "#A998A6",
          fillColor: "transparent",
        },
        nodeSelected: {
          fillColor: "transparent",
        },
        nodeHovered: {
          shadowBlur: 0,
          shadowColor: null,
          shadowOffsetY: 0,
          lineWidth: 7,
          lineColor: "#054379",
        },
        link: { fillColor: "#A998A6" },
        linkHovered: { shadowBlur: 0, shadowColor: null },
        item: {
          backgroundStyle: {
            fillColor: "transparent",
            lineColor: "transparent",
          },
          scaleWithZoom: true,
          align: "center",
          hoverEffect: false,
        },
        nodeStyleFunction: nodeStyle,
        linkStyleFunction: linkHighlight,
      },
    });
    // eslint-disable-next-line
  }, [changeTargetNode]);

  useEffect(() => {
    if (filters.visited) {
      showAll(true);
    }

    // eslint-disable-next-line
  }, [filters]);

  const nodeFilter = useCallback(
    (nodeData) => {
      if (nodeData.extra.isCountry) {
        return nodeData;
      }

      let appliedFilters = [];

      if (filters.beneficiaries) {
        appliedFilters.push(filters.beneficiaries);
      }

      if (filters.sectors) {
        appliedFilters.push(filters.sectors);
      }

      if (filters.funding) {
        appliedFilters.push(filters.funding);
      }

      if (filters.services) {
        appliedFilters.push(filters.services);
      }

      if (appliedFilters.length < 1) {
        return nodeData;
      }

      const nodeFilters = nodeData?.extra?.filters || null;

      let beneficiariesNodeFilters = [];
      if (nodeFilters.beneficiaries.length > 0) {
        let beneficiariesNodeFiltersIds = [];

        nodeFilters.beneficiaries.forEach((element) => {
          if (!beneficiariesNodeFiltersIds.includes(element.id)) {
            beneficiariesNodeFiltersIds.push(element.id);
            beneficiariesNodeFilters.push(element.name.toLowerCase());
          }
        });
      }

      let sectorsNodeFilters = [];
      if (nodeFilters.sectors.length > 0) {
        let sectorsNodeFiltersIds = [];

        nodeFilters.sectors.forEach((element) => {
          if (!sectorsNodeFiltersIds.includes(element.id)) {
            sectorsNodeFiltersIds.push(element.id);
            sectorsNodeFilters.push(element.name.toLowerCase());
          }
        });
      }

      let fundingNodeFilters = [];
      if (nodeFilters.fundings.length > 0) {
        let fundingNodeFiltersIds = [];

        nodeFilters.fundings.forEach((element) => {
          if (!fundingNodeFiltersIds.includes(element.id)) {
            fundingNodeFiltersIds.push(element.id);
            fundingNodeFilters.push(element.name.toLowerCase());
          }
        });
      }

      let servicesNodeFilters = [];
      if (nodeFilters.services.length > 0) {
        let servicesNodeFiltersIds = [];

        nodeFilters.services.forEach((element) => {
          if (!servicesNodeFiltersIds.includes(element.id)) {
            servicesNodeFiltersIds.push(element.id);
            servicesNodeFilters.push(element.name.toLowerCase());
          }
        });
      }

      let allNodeFilters = [];
      allNodeFilters = beneficiariesNodeFilters.concat(
        sectorsNodeFilters,
        fundingNodeFilters,
        servicesNodeFilters
      );

      // return allNodeFilters.some((element) => {
      //   return appliedFilters.includes(element);
      // });

      return appliedFilters.every((element) => {
        return allNodeFilters.includes(element);
      });
    },
    [filters]
  );

  useEffect(() => {
    networkGraphRef.current.updateSettings({
      filters: {
        nodeFilter: nodeFilter,
      },
    });
  }, [nodeFilter]);

  return (
    <div className="networkGraph">
      {response.isFetching && <Spinner />}

      <div id="networkGraphContainer"></div>

      <div className="site-logo-container">
        <SiteLogo />

        <CollaborateWithUs />
      </div>

      <PoweredBy />

      {responseData === undefined ? null : responseData &&
        responseData?.status !== "fail" ? (
        <>
          <WelcomeModal />

          {responseData?.status !== "fail" && (
            <div className="zoomChartActions">
              <ZoomControls
                zoomLevel={zoomLevel}
                onZoomChange={onZoomChange}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
              />

              <ToggleMode
                isModeToggled={isModeToggled}
                handleModeChange={handleModeChange}
              />
            </div>
          )}

          <div className="filters-container">
            {responseData?.status !== "fail" && (
              <ShowAllButton
                isShowingAll={isShowingAll}
                collapseAllNodes={showAll}
                setIsSidePanelActive={setIsSidePanelActive}
              />
            )}

            <CountryView
              setFilter={setFilter}
              countries={responseData?.countries}
              countryID={countryID}
              setCountryID={setCountryID}
              setIsSidePanelActive={setIsSidePanelActive}
            />

            <Filters
              filters={filters}
              dispatchFilters={dispatchFilters}
              filtersData={responseData?.filters || null}
            />

            {responseData?.status !== "fail" && (
              <ResetFiltersButton resetFilters={resetFilters} />
            )}
          </div>

          <SidePanel
            isSidePanelActive={isSidePanelActive}
            handleSidePanelClose={handleSidePanelClose}
            nodeData={nodeData?.extra}
          />
        </>
      ) : (
        <div className="responseError">
          <p>
            Failed to load data. Please refresh the page or try again later.
          </p>
        </div>
      )}
    </div>
  );
};

export default NetworkGraph;

import keys from "../../config/keys";

import "./index.css";

const CollaborateWithUs = () => {
  return (
    <a className="collaborateWithUs" href={`mailto:${keys.collaborateEmail}`}>
      Share Updates
    </a>
  );
};

export default CollaborateWithUs;

import globeIcon from "../../assets/media/globe-icon.svg";

import "./index.css";

const ShowAllButton = ({
  isShowingAll,
  collapseAllNodes,
  setIsSidePanelActive,
}) => {
  return (
    <button
      className="showAllButton"
      onClick={() => {
        setIsSidePanelActive(false);

        collapseAllNodes();
      }}
    >
      <img src={globeIcon} alt="globe"></img>

      <span>{isShowingAll ? "Collapse All" : "Show All"}</span>
    </button>
  );
};

export default ShowAllButton;

import Title from "../Title";

import "./index.css";

const PartnersAndSponsors = ({ partnersAndSponsors = [] }) => {
  return (
    <div className="partnersAndSponsors">
      <Title>Government Partners & Sponsors</Title>

      <div className="partnersAndSponsors__logosWrap">
        {partnersAndSponsors.map((row) => (
          <a
            key={row.id}
            href={row.URL}
            target="_blank"
            rel="noreferrer"
            title={row.name}
            style={{ cursor: row.URL && "pointer" }}
          >
            <img
              className="partnersAndSponsors__logo"
              src={row.logo}
              alt="sponsor"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default PartnersAndSponsors;

import Title from "../Title";

import "./index.css";

const ParentInstitutions = ({ institutions = [] }) => {
  return (
    <div className="parentInstitutions">
      <Title>Parent Institution</Title>

      <div className="parentInstitutions__logosWrap">
        {institutions.map((row) => (
          <a
            key={row.id}
            href={row.URL}
            target="_blank"
            rel="noreferrer"
            title={row.name}
            style={{ cursor: row.URL && "pointer" }}
          >
            <img
              className="parentInstitutions__logo"
              src={row.logo}
              alt="institution"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default ParentInstitutions;

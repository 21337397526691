import Title from "../Title";
import Paragraph from "../Paragraph";

const CompanyMission = ({ companyName, mission }) => {
  return (
    <>
      <Title>About {companyName}</Title>

      <Paragraph>{mission}</Paragraph>
    </>
  );
};

export default CompanyMission;

import "./index.css";

const ToggleMode = ({ isModeToggled = false, handleModeChange }) => {
  return (
    <div className="toggleMode">
      <input
        className="toggleMode__input"
        type="checkbox"
        id="toggleInput"
        onChange={handleModeChange}
        checked={isModeToggled}
      />

      <label className="toggleMode__label" htmlFor="toggleInput"></label>

      <p className="toggleMode__mode">{isModeToggled ? "Radial" : "Dynamic"}</p>
    </div>
  );
};

export default ToggleMode;
